import { RuleFunction } from "@/store";
import { ref, onBeforeMount } from "@vue/composition-api";

export function useDateValidation() {
  const eighteenYearsAgo = ref<Date>(new Date());
  const today = ref<Date>(new Date());

  const birthDateRules = ref<RuleFunction<Date | string>[]>([
    (value: Date | string) => !!value || "Birth date is required",
    (value: Date | string) =>
      new Date(value) < eighteenYearsAgo.value ||
      "Birth date must be at least 18 years ago",
  ]);

  const dogBirthDateRules = ref<RuleFunction<Date>[]>([
    (value: Date) => !!value || "Birth date is required",
    (value: Date | string) =>
      new Date(value) <= today.value || "Birth date must be less than today",
  ]);

  onBeforeMount(() => {
    today.value = new Date();
    const now = new Date();
    now.setFullYear(now.getFullYear() - 18);
    eighteenYearsAgo.value = now;
  });

  return {
    birthDateRules,
    eighteenYearsAgo,
    today,
    dogBirthDateRules,
  };
}
