import { RuleFunction } from "@/store";
import { ref } from "@vue/composition-api";

export function useUsernameValidation() {
  // https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/aws-resource-cognito-userpooluser.html
  const usernameFormat = new RegExp(/^[\S]{5,}$/m);

  const usernameRules = ref<RuleFunction<string>[]>([
    (value: string) => (!!value && value.length >= 1) || "Username is required",
    (value: string) =>
      value.length >= 5 || "Username must be at least 5 characters long",
    (value: string) =>
      value.length <= 128 || "Username must be less than 128 characters long",
    (value: string) =>
      usernameFormat.test(value) ||
      "Username can only container characters, symbols, and numbers",
  ]);

  return {
    usernameRules,
    usernameFormat,
  };
}
