












































































































import { defineComponent, ref, watchEffect } from "@vue/composition-api";
import { RuleFunction, Provider } from "@/store";
import {
  useEmailValidation,
  useNameValidation,
  usePhoneValidation,
} from "@/composables/validation";
import { SPECIALTIES } from "@/store/constants";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";

interface Props {
  loading: boolean;
  providers?: Provider[];
  update?: boolean;
  provider?: Provider;
  providerExists?: boolean;
  validate?: boolean;
  takenSpecialities?: number[];
}

export default defineComponent({
  name: "ProviderForm",
  props: {
    loading: Boolean,
    providers: Array,
    update: Boolean,
    provider: Object,
    providerExists: Boolean,
    validate: Boolean,
    takenSpecialities: Array,
  },
  components: {
    ConfirmationDialog,
  },

  setup(props: Props, { emit }) {
    const { phoneMask } = usePhoneValidation();
    const firstName = ref<string>("");
    const lastName = ref<string>("");
    const specialty = ref<number | null>(null);
    const phone = ref<string>("");
    const email = ref<string>("");
    const firstNameRules = ref<RuleFunction<string>[]>([]);
    const lastNameRules = ref<RuleFunction<string>[]>([]);
    const phoneRules = ref<RuleFunction<string>[]>([]);
    const specialtyRules = ref<RuleFunction<string>[]>([]);
    const emailRules = ref<RuleFunction<string>[]>([]);
    const valid = ref<boolean>(false);
    const providerForm = ref<{ validate: Function } | null>(null);
    const existingProvider = ref<number | null>(null);
    const editMode = ref<boolean>(true);

    watchEffect(() => {
      if (props.validate && providerForm.value) {
        providerForm.value.validate();
      }
    });

    function setRequired() {
      const allNameRules = useNameValidation();
      firstNameRules.value = allNameRules.firstNameRules.value;
      lastNameRules.value = allNameRules.lastNameRules.value;
      phoneRules.value = usePhoneValidation().phoneRules.value;
      emailRules.value = useEmailValidation(false).emailRules.value;
      specialtyRules.value = [
        (value: string) => !!value || "Specialty is required",
      ];

      providerForm.value?.validate();
    }

    function setNotRequired() {
      firstNameRules.value = [];
      lastNameRules.value = [];
      phoneRules.value = [];
      specialtyRules.value = [];
      emailRules.value = [];

      providerForm.value?.validate();
    }

    async function setProvider() {
      if (
        specialty.value ||
        (firstName.value || "").trim().length ||
        (lastName.value || "").trim().length ||
        (phone.value || "").trim().length ||
        (email.value || "").trim().length
      ) {
        await setRequired();
      } else {
        await setNotRequired();
      }

      emit("applicantProvider", {
        provider: {
          firstName: firstName.value,
          lastName: lastName.value,
          phone: phone.value,
          specialty: specialty.value,
          email: email.value,
        },
        valid: valid.value,
      });

      watchEffect(() => {
        if (valid.value && providerForm.value) {
          setTimeout(() => {
            emit("applicantProvider", {
              provider: {
                firstName: firstName.value,
                lastName: lastName.value,
                phone: phone.value,
                specialty: specialty.value,
                email: email.value,
              },
              valid: valid.value,
              existing: existingProvider.value,
            });
          }, 300);
        }
      });
    }

    async function clearAll(force = false) {
      if (!(props.update && props.provider) || force) {
        await setNotRequired();
        firstName.value = "";
        lastName.value = "";
        phone.value = "";
        email.value = "";
        specialty.value = null;
        existingProvider.value = null;
      }
    }

    async function selectProvider(provider: Provider) {
      if (provider) {
        await setRequired();
        firstName.value = provider.firstName;
        lastName.value = provider.lastName;
        phone.value = provider.phone.slice(2, 13);
        email.value = provider.email;
        specialty.value = provider.specialty;
        existingProvider.value = provider.id as number;

        emit("applicantProvider", {
          provider: {
            firstName: firstName.value,
            lastName: lastName.value,
            phone: phone.value,
            specialty: specialty.value,
            email: email.value,
          },
          valid: true,
          existing: existingProvider.value,
        });
      } else {
        await clearAll();
      }
    }

    function providerFilter(provider: Provider, query: string): boolean {
      const providerText = `${provider.firstName} ${provider.lastName}
        ${provider.phone} ${provider.email || ""}`.toLowerCase();
      return providerText.includes(query.toLowerCase());
    }

    async function removeProvider() {
      await clearAll(true);
      editMode.value = true;
      emit("removeProvider");
    }

    async function setFields(provider: Provider) {
      firstName.value = provider.firstName;
      lastName.value = provider.lastName;
      phone.value = provider.phone.slice(2, 13);
      specialty.value = provider.specialty;
      email.value = provider.email;
      existingProvider.value = provider.id as number;
      await setRequired();
    }

    function showButtons() {
      return !!(
        props.update &&
        props.provider &&
        props.providerExists &&
        existingProvider.value
      );
    }

    if (props.update) {
      if (props.provider) {
        setFields(props.provider as Provider);
        editMode.value = false;
      } else {
        setNotRequired();
      }
    }

    function getAvailableSpecialities(taken: number[]) {
      return SPECIALTIES.filter((s) => !(taken || []).includes(s.value));
    }

    function getProviders(): Provider[] | undefined {
      return props.providers?.filter(
        (p) => !props.takenSpecialities?.includes(p.specialty)
      );
    }

    return {
      valid,
      providerForm,
      phoneMask,
      firstName,
      lastName,
      specialty,
      specialtyRules,
      firstNameRules,
      lastNameRules,
      phoneRules,
      emailRules,
      email,
      phone,
      setProvider,
      getAvailableSpecialities,
      providerFilter,
      setFields,
      removeProvider,
      showButtons,
      selectProvider,
      editMode,
      getProviders,
    };
  },
});
