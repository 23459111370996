import { RuleFunction } from "@/store";
import { ref } from "@vue/composition-api";

export function usePhotoValidation() {
  const photoRules = ref<RuleFunction<File>[]>([
    (value: File) =>
      !value ||
      ["image/png", "image/jpeg"].includes(value.type) ||
      "Photo must be a png or jpg",
    (value: File) =>
      !value || value.size < 5 * 1000000 || "photo size must be less than 5MB",
  ]);

  return {
    photoRules,
  };
}
