import { RuleFunction } from "@/store";
import { ref } from "@vue/composition-api";

export function useFileValidation() {
  const fileRules = ref<RuleFunction<File>[]>([
    (value: File) =>
      !value ||
      ["application/pdf"].includes(value.type) ||
      "File must be a pdf",
    (value: File) =>
      !value || value.size < 15 * 1000000 || "File size must be less than 15MB",
  ]);

  const fileArrayRules = ref<RuleFunction<File[]>[]>([
    (files) =>
      !files ||
      !files.some((file) => file.size > 15 * 1000000) ||
      "File size must be less than 15MB",
    (files) =>
      !files ||
      !files.some((file) => file.type !== "application/pdf") ||
      "File must be a pdf",
  ]);

  return {
    fileRules,
    fileArrayRules,
  };
}
