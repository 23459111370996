
































import {
  defineComponent,
  computed,
  onBeforeMount,
  watch,
} from "@vue/composition-api";
import {
  Applicant,
  ApplicantUpdateBody,
  Service,
  User,
  Provider,
} from "@/store";
import {
  useStore,
  useApplicantService,
  useRouter,
  useServiceTypeService,
  useUserService,
  useProviderService,
} from "@/composables/providers";
import { ApplicantActions, ApplicantGetters } from "@/store/applicant";
import { ServiceGetters, ServiceActions } from "@/store/service-type";
import ApplicantForm from "@/components/forms/Applicant.vue";
import { UserGetters, UserActions } from "@/store/user";
import { SessionGetters } from "@/store/session";
import { ProviderActions, ProviderGetters } from "@/store/provider";

export default defineComponent({
  name: "ApplicantsUpdate",
  components: {
    ApplicantForm,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const users = computed<User>(() => store.getters[UserGetters.USERS]);
    const servicesService = useServiceTypeService();
    const applicantService = useApplicantService();
    const providerService = useProviderService();
    const userService = useUserService();
    const currentUser = computed<User>(
      () => store.getters[SessionGetters.USER]
    );
    const applicantsLoading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );
    const usersLoading = computed<boolean>(
      () => store.getters[UserGetters.LOADING]
    );
    const providersLoading = computed<boolean>(
      () => store.getters[ProviderGetters.LOADING]
    );

    const providers = computed<Provider[]>(
      () => store.getters[ProviderGetters.PROVIDERS]
    );
    const servicesLoading = computed<boolean>(
      () => store.getters[ServiceGetters.LOADING]
    );
    const loading = computed<boolean>(
      () =>
        applicantsLoading.value ||
        servicesLoading.value ||
        usersLoading.value ||
        providersLoading.value
    );
    const militaryServices = computed<Service[]>(
      () => store.getters[ServiceGetters.MILITARY_SERVICES]
    );
    const responderServices = computed<Service[]>(
      () => store.getters[ServiceGetters.RESPONDER_SERVICES]
    );

    const isAdmin = computed<boolean>(
      () => store.getters[SessionGetters.IS_SITE_ADMIN_OR_ORGANIZATION_ADMIN]
    );

    const id = computed<string>(() => router.currentRoute.params["id"] || "");

    async function submit(body: ApplicantUpdateBody) {
      await store.dispatch(ApplicantActions.UPDATE, {
        id: id.value,
        body,
        service: applicantService,
      });
      await router.push({ name: "ApplicantDetail", params: { id: id.value } });
      await store.dispatch(ProviderActions.LIST, {
        service: providerService,
        force: true,
      });
    }

    const selected = computed<Applicant | undefined>(
      () => store.getters[ApplicantGetters.SELECTED]
    );

    async function cancelEdit() {
      await router.push({
        name: "ApplicantDetail",
        params: { id: ((selected.value || {}).id || "").toString() },
      });
    }

    onBeforeMount(async () => {
      await Promise.all([
        store.dispatch(ApplicantActions.SELECT, {
          id: id.value,
          service: applicantService,
        }),
        await store.dispatch(ServiceActions.LIST, { service: servicesService }),
        await store.dispatch(UserActions.LIST, { service: userService }),
        await store.dispatch(ProviderActions.LIST, {
          service: providerService,
        }),
      ]);
    });

    watch(id, async (newVal) => {
      await store.dispatch(ApplicantActions.SELECT, {
        id: newVal,
        service: applicantService,
      });
    });

    return {
      submit,
      loading,
      selected,
      cancelEdit,
      militaryServices,
      responderServices,
      users,
      isAdmin,
      currentUser,
      providers,
    };
  },
});
