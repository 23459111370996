import { RuleFunction } from "@/store";
import { ref } from "@vue/composition-api";

export function useAddressValidation() {
  const verifyZipCodeFormat = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");

  const zipCodeRules = ref<RuleFunction<string>[]>([
    (value: string) => (!!value && value.length >= 1) || "Zip code is required",
    (value: string) =>
      verifyZipCodeFormat.test(value) || "Valid zip code is required",
  ]);

  const cityRules = ref<RuleFunction<string>[]>([
    (value: string) => (!!value && value.length >= 1) || "City is required",
  ]);

  const stateRules = ref<RuleFunction<string>[]>([
    (value: string) =>
      (!!value && value.length >= 1) || "State or territory is required",
  ]);

  const streetAddressRules = ref<RuleFunction<string>[]>([
    (value: string) =>
      (!!value && value.length >= 1) || "Street address is required",
  ]);

  return {
    zipCodeRules,
    cityRules,
    stateRules,
    streetAddressRules,
  };
}
