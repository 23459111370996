

















































































































































































































































































































































































































































































import {
  defineComponent,
  ref,
  watch,
  computed,
  onMounted,
} from "@vue/composition-api";
import { STATE_LIST, APPLICANT_STATUS_LIST } from "@/store/constants";
import ApplicantNoteForm from "@/components/forms/ApplicantNote.vue";
import SponsorForm from "@/components/forms/Sponsor.vue";
import ProviderForm from "@/components/forms/Provider.vue";
import ApplicantAttachmentUpdateForm from "@/components/forms/ApplicantAttachmentUpdate.vue";
import ApplicantEmergencyContactForm from "@/components/forms/ApplicantEmergencyContact.vue";
import {
  ApplicantCreateBody,
  Applicant,
  ApplicantUpdateBody,
  Service,
  RuleFunction,
  User,
  ApplicantNote,
  Sponsor,
  Provider,
  ApplicantEmergencyContact,
} from "@/store";
import {
  useNameValidation,
  useEmailValidation,
  useAddressValidation,
  usePhoneValidation,
  useDateValidation,
  usePhotoValidation,
  useFileValidation,
} from "@/composables/validation";

interface Services {
  text: string;
  value: number;
}

interface Props {
  update: boolean;
  loading: boolean;
  militaryServices: Service[];
  responderServices: Service[];
  applicant?: Applicant;
  user: User;
  users?: User[];
  isAdmin?: boolean;
  providers?: Provider[];
}

export default defineComponent({
  name: "ApplicantForm",
  props: {
    update: Boolean,
    applicant: Object,
    loading: Boolean,
    militaryServices: Array,
    responderServices: Array,
    user: Object,
    users: Array,
    isAdmin: Boolean,
    providers: Array,
  },
  components: {
    ApplicantNoteForm,
    ProviderForm,
    SponsorForm,
    ApplicantEmergencyContactForm,
    ApplicantAttachmentUpdateForm,
  },
  setup(props: Props, { emit }) {
    const firstName = ref<string>("");
    const lastName = ref<string>("");
    const birthDate = ref<Date | string>("");
    const phone = ref<string>("");
    const homePhone = ref<string>("");
    const addressLine1 = ref<string>("");
    const addressLine2 = ref<string>("");
    const city = ref<string>("");
    const stateOrTerritory = ref<string>("");
    const zipCode = ref<string>("");
    const email = ref<string>("");
    const photo = ref<File | null>(null);
    const startDate = ref<Date | string>("");
    const homeVisitDate = ref<Date | string>("");
    const valid = ref<boolean>(false);
    const applicantForm = ref<{ validate: Function } | null>(null);
    const birthDateMenu = ref<boolean>(false);
    const startDateMenu = ref<boolean>(false);
    const homeVisitDateMenu = ref<boolean>(false);
    const responderSelected = ref<number[]>([]);
    const militarySelected = ref<number[]>([]);
    const applicantNote = ref<ApplicantNote[]>([]);
    const noteError = ref<boolean>(false);
    const notesValid = ref<boolean>(true);
    const newNoteAdded = ref<boolean>(false);
    const applicantSponsor = ref<Sponsor | null>(null);
    const sponsorError = ref<boolean>(false);
    const sponsorValid = ref<boolean>(true);
    const sponsorUpdated = ref<boolean>(false);
    const applicantEmergencyContact = ref<ApplicantEmergencyContact | null>(
      null
    );
    const emergencyContactError = ref<boolean>(false);
    const emergencyContactValid = ref<boolean>(true);
    const emergencyContactUpdated = ref<boolean>(false);
    const existingEmergencyContact = ref<number | null>(
      (((props.applicant || {}).emergencyContact || {}).id as number) || null
    );
    const status = ref<number>();
    const applicantProvider = ref<Provider | null>(null);
    const providerError = ref<boolean>(false);
    const providerValid = ref<boolean>(true);
    const providerUpdated = ref<boolean>(false);
    const validate = ref<boolean>(false);
    const additionalProvidersIds = ref<number[]>([]);
    const additionalProviders = ref<Provider[]>([]);
    const additionalProvidersUpdated = ref<boolean>(false);
    const takenSpecialities = ref<{ [key: string]: number[] }>({});
    const existingSponsor = ref<number | null>(
      (((props.applicant || {}).sponsor || {}).id as number) || null
    );
    const homeVisitFile = ref<File | null>(null);
    const applicationFile = ref<File | null>(null);
    const serviceEmploymentFiles = ref<File[] | null>(null);
    const medicalFiles = ref<File[] | null>(null);
    const contractFile = ref<File | null>(null);
    const newNote = ref<ApplicantNote | null>(null);
    const notes = ref<ApplicantNote[] | undefined>(
      (((props.applicant || {}).notes as ApplicantNote[]) || []).sort(
        (a: ApplicantNote, b: ApplicantNote) => {
          const dateA = new Date(a.createDate).getTime();
          const dateB = new Date(b.createDate).getTime();
          if (dateA > dateB) {
            return 1;
          }
          if (dateB > dateA) {
            return -1;
          }
          return 0;
        }
      )
    );

    const notesUpdated = ref<boolean>(false);

    const picker = ref<{ activePicker: string }>();
    const photoSource = ref<string | undefined>(
      props.applicant && props.applicant.displayPhoto
        ? props.applicant.displayPhoto
        : `${require("../../assets/placeholder.png")}`
    );

    const updatedPhoto = ref<boolean>(false);
    const responderArray = computed<Services[]>(() =>
      props && props.responderServices
        ? props.responderServices.map((service) => {
            return { text: service.name, value: service.id };
          })
        : []
    );
    const militaryArray = computed<Services[]>(() =>
      props && props.militaryServices
        ? props.militaryServices.map((service) => {
            return { text: service.name, value: service.id };
          })
        : []
    );

    const deletedFiles = ref<number[] | undefined>();
    const filesValid = ref<boolean>(true);

    function changeFiles(event: boolean) {
      filesValid.value = event;
    }

    function getClearNote(event: {
      valid: boolean;
      note?: ApplicantNote;
      index?: number;
      type: string;
    }) {
      return !!(
        (event.note &&
          event.note?.text?.length <= 3 &&
          (event.index || 0) &&
          applicantNote.value[0]) ||
        event.type === "new"
      );
    }

    function checkValidNote(event: {
      valid: boolean;
      note?: ApplicantNote;
      index?: number;
      type: string;
    }) {
      return !!(
        !noteError.value &&
        event.note &&
        (event.note.text || "").length > 3
      );
    }

    function setApplicantNote(event: {
      valid: boolean;
      note?: ApplicantNote;
      index?: number;
      type: string;
    }) {
      noteError.value = !event.valid;
      if (checkValidNote(event) && event.type !== "new") {
        applicantNote.value[event.index || 0] = event.note as ApplicantNote;
        notesUpdated.value = true;
      } else if (checkValidNote(event) && event.type === "new") {
        newNote.value = event.note as ApplicantNote;
        newNoteAdded.value = true;
      } else if (getClearNote(event)) {
        if (event.type !== "new")
          applicantNote.value.splice(event.index || 0, 1);
        else {
          newNote.value = null;
          newNoteAdded.value = false;
        }
      }
      notesValid.value = event.valid;
    }

    function getSponsorValidEvent(event: {
      valid: boolean;
      sponsor?: Sponsor;
      deleted?: boolean;
    }) {
      return !!(
        event.sponsor &&
        event.sponsor.firstName &&
        event.sponsor.lastName &&
        event.sponsor.phone.length > 2 &&
        event.sponsor.relationship
      );
    }

    function setApplicantSponsor(event: {
      valid: boolean;
      sponsor?: Sponsor;
      deleted?: boolean;
    }) {
      sponsorError.value = !event.valid;

      if (!sponsorError.value && event.sponsor && getSponsorValidEvent(event)) {
        applicantSponsor.value = event.sponsor;
        applicantSponsor.value.phone = "+1" + event.sponsor.phone;

        if (existingSponsor.value && props.update) {
          applicantSponsor.value.id = existingSponsor.value;
        }
      }
      if (existingSponsor.value && event.deleted) {
        applicantSponsor.value = (props.applicant || {}).sponsor as Sponsor;
        applicantSponsor.value.delete = event.deleted;
      }
      if (props.update && (getSponsorValidEvent(event) || event.deleted))
        sponsorUpdated.value = true;
      else sponsorUpdated.value = false;
      sponsorValid.value = event.valid;
    }

    function getEmergencyContactValidEvent(event: {
      valid: boolean;
      contact?: ApplicantEmergencyContact;
      deleted?: boolean;
    }) {
      return !!(
        event.contact &&
        event.contact.firstName &&
        event.contact.lastName &&
        event.contact.relationship &&
        event.contact.phone &&
        event.contact.phone.length > 2
      );
    }

    function setApplicantEmergencyContact(event: {
      valid: boolean;
      contact?: ApplicantEmergencyContact;
      deleted?: boolean;
    }) {
      emergencyContactError.value = !event.valid;
      if (
        !emergencyContactError.value &&
        event.contact &&
        getEmergencyContactValidEvent(event)
      ) {
        applicantEmergencyContact.value = event.contact;
        applicantEmergencyContact.value.phone = `+1${event.contact.phone}`;
        if (existingEmergencyContact.value && props.update) {
          applicantEmergencyContact.value.id = existingEmergencyContact.value;
        }
      }
      if (existingEmergencyContact.value && event.deleted) {
        applicantEmergencyContact.value = (props.applicant || {})
          .emergencyContact as ApplicantEmergencyContact;
        applicantEmergencyContact.value.delete = event.deleted;
      }
      emergencyContactUpdated.value =
        (props.update && getEmergencyContactValidEvent(event)) ||
        !!event.deleted;
      emergencyContactValid.value = event.valid;
    }

    function getProviderEventValid(event: {
      valid: boolean;
      provider?: Provider;
      existing?: number | null;
    }) {
      return !!(
        event.provider &&
        event.provider.firstName &&
        event.provider.lastName &&
        event.provider.specialty &&
        event.provider.phone &&
        event.provider.phone.length > 2
      );
    }

    function setTakenSpecialities(): void {
      const general =
        additionalProviders.value
          .map((p) => p?.specialty)
          .filter((p) => p != null) || [];

      const additional: { [key: string]: number[] } = {};
      additionalProvidersIds.value.forEach((index) => {
        const taken =
          additionalProviders.value
            .map((p) => p?.specialty)
            .filter((p, i) => i !== index && p != null) || [];
        if (applicantProvider.value?.specialty) {
          taken.push(applicantProvider.value?.specialty);
        }
        additional[`additional-${index}`] = taken;
      });

      takenSpecialities.value = { general, ...additional };
    }

    function setApplicantProvider(event: {
      valid: boolean;
      provider?: Provider;
      existing?: number | null;
    }) {
      providerError.value = !event.valid;
      if (
        !providerError.value &&
        event.provider &&
        getProviderEventValid(event)
      ) {
        applicantProvider.value = event.provider;
        if (event.existing) {
          applicantProvider.value.id = event.existing;
        }
        applicantProvider.value.phone = "+1" + event.provider.phone;
      }

      if (props.update && getProviderEventValid(event)) {
        providerUpdated.value = true;
      }

      if (!getProviderEventValid(event)) {
        if (event.provider?.specialty) {
          applicantProvider.value = event.provider as Provider;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          applicantProvider.value = (null as any) as Provider;
        }
      }

      providerValid.value = event.valid;
      setTakenSpecialities();
    }

    function removeProvider() {
      applicantProvider.value = null;
      providerUpdated.value = true;
      providerError.value = false;
      providerValid.value = true;
      setTakenSpecialities();
    }

    function addProvider() {
      additionalProvidersIds.value = [
        ...Array(additionalProvidersIds.value.length + 1).keys(),
      ];
      setTakenSpecialities();
    }

    function setAdditionalProvider(
      event: {
        valid: boolean;
        provider?: Provider;
        existing?: number | null;
      },
      id: number
    ) {
      providerError.value = !event.valid;
      if (
        !providerError.value &&
        event.provider &&
        getProviderEventValid(event)
      ) {
        additionalProviders.value[id] = event.provider;
        if (event.existing) {
          additionalProviders.value[id].id = event.existing;
        }
        additionalProviders.value[id].phone = "+1" + event.provider.phone;
      }

      if (props.update && getProviderEventValid(event)) {
        additionalProvidersUpdated.value = true;
      }

      if (!getProviderEventValid(event)) {
        if (event.provider?.specialty) {
          additionalProviders.value[id] = event.provider as Provider;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          additionalProviders.value[id] = (null as any) as Provider;
        }
      }

      providerValid.value = event.valid;
      setTakenSpecialities();
    }

    function removeAdditionalProvider(id: number) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      additionalProviders.value[id] = (null as any) as Provider;
      additionalProvidersUpdated.value = true;
      providerError.value = false;
      providerValid.value = true;
    }

    function deleteFile($event: number[]) {
      deletedFiles.value = $event;
    }

    function setMedicalFiles($event: File[]) {
      medicalFiles.value = $event;
    }

    function setServiceEmploymentFiles($event: File[]) {
      serviceEmploymentFiles.value = $event;
    }

    function setApplicationFile($event: File) {
      applicationFile.value = $event;
    }

    function setHomeVisitFile($event: File) {
      homeVisitFile.value = $event;
    }

    function setContractFile($event: File) {
      contractFile.value = $event;
    }

    function updateFormFromProps() {
      if (props.update && props.applicant) {
        firstName.value = props.applicant.firstName;
        lastName.value = props.applicant.lastName;
        city.value = props.applicant.city;
        stateOrTerritory.value = props.applicant.stateOrTerritory;
        zipCode.value = props.applicant.zipCode;
        addressLine1.value = props.applicant.addressLine1;
        birthDate.value = new Date(props.applicant.birthDate)
          .toISOString()
          .substr(0, 10);

        responderSelected.value = (props.applicant.services as Service[]).map(
          (service: Service) => {
            if (service.type === "responder") {
              return service.id;
            }
          }
        ) as number[];

        militarySelected.value = (props.applicant.services as Service[]).map(
          (service: Service) => {
            if (service.type === "military") {
              return service.id;
            }
          }
        ) as number[];

        if (props.applicant.phone) {
          phone.value = props.applicant.phone?.slice(2, 13);
        }

        if (props.applicant.homePhone) {
          homePhone.value = props.applicant.homePhone?.slice(2, 13);
        }

        if (props.applicant.email) {
          email.value = props.applicant.email;
        }

        if (props.applicant.addressLine2) {
          addressLine2.value = props.applicant.addressLine2;
        }

        if (props.applicant.status) {
          status.value = props.applicant.status;
        }

        if (props.applicant.startDate) {
          startDate.value = new Date(props.applicant.startDate)
            .toISOString()
            .substr(0, 10);
        }

        if (props.applicant.homeVisitDate) {
          homeVisitDate.value = new Date(props.applicant.homeVisitDate)
            .toISOString()
            .substr(0, 10);
        }

        if (props.applicant.notes) {
          applicantNote.value = notes.value as ApplicantNote[];
        }

        if (props.applicant.provider) {
          applicantProvider.value = props.applicant.provider;
        }

        if (props.applicant.additionalProviders) {
          additionalProviders.value = props.applicant.additionalProviders;
          additionalProvidersIds.value = props.applicant.additionalProviders.map(
            (_, i) => i
          );
        }

        if (props.applicant.sponsor) {
          applicantSponsor.value = props.applicant.sponsor;
        }

        if (props.applicant.emergencyContact) {
          applicantEmergencyContact.value = props.applicant.emergencyContact;
        }

        setTakenSpecialities();
      }
    }

    onMounted(() => {
      updateFormFromProps();
    });

    watch(
      () => props.loading,
      () => {
        updateFormFromProps();
      }
    );

    const panel = ref<number[]>([0, 1]);
    const expanded = ref<boolean>(true);

    watch(panel, (newVal) => {
      if (newVal && newVal.length > 0) {
        expanded.value = true;
      } else {
        expanded.value = false;
      }
    });

    function openPanels() {
      panel.value = [0, 1];
      if (sponsorError.value) {
        panel.value.push(2);
      }

      if (providerError.value) {
        panel.value.push(3);
      }
      if (emergencyContactError.value) {
        panel.value.push(4);
      }
      if (!filesValid.value) {
        panel.value.push(5);
      }
      if (noteError.value) {
        panel.value.push(6);
      }
    }

    function closeOrOpenPanels() {
      if (expanded.value) {
        panel.value = [];
      } else {
        panel.value = [0, 1, 2, 3, 4, 5];
      }
    }
    watch(birthDateMenu, (newVal) => {
      if (newVal && picker.value) {
        picker.value.activePicker = "YEAR";
      }
    });

    function transformServices() {
      const transformedServices: Service[] = [];
      if (responderSelected.value && responderSelected.value.length) {
        responderSelected.value.forEach((selected: number) => {
          props.responderServices.forEach((service: Service) => {
            if (selected === service.id) {
              transformedServices.push(service);
            }
          });
        });
      }
      if (militarySelected.value && militarySelected.value.length) {
        militarySelected.value.forEach((selected: number) => {
          props.militaryServices.forEach((service: Service) => {
            if (selected === service.id) {
              transformedServices.push(service);
            }
          });
        });
      }

      return transformedServices;
    }

    function deleteNote(event: number) {
      notesValid.value = true;
      noteError.value = false;
      applicantNote.value = applicantNote.value.filter(
        (note) => note.id !== event
      );
      notesUpdated.value = true;
    }

    function getUpdates(): ApplicantUpdateBody {
      const body: ApplicantUpdateBody = {};
      const updatedServices: Service[] = transformServices();
      if (props.applicant) {
        if (firstName.value && firstName.value !== props.applicant.firstName) {
          body.firstName = firstName.value;
        }
        if (lastName.value && lastName.value !== props.applicant.lastName) {
          body.lastName = lastName.value;
        }
        if (
          phone.value &&
          phone.value !== props.applicant.phone?.slice(3 - 13)
        ) {
          body.phone = "+1" + phone.value;
        }
        if (
          phone.value === "" &&
          phone.value !== props.applicant.phone?.slice(3 - 13)
        ) {
          body.phone = "";
        }
        if (
          homePhone.value &&
          homePhone.value !== props.applicant.homePhone?.slice(3 - 13)
        ) {
          body.homePhone = "+1" + homePhone.value;
        }
        if (
          homePhone.value === "" &&
          homePhone.value !== props.applicant.homePhone?.slice(3 - 13)
        ) {
          body.homePhone = "";
        }
        if (email.value && email.value !== props.applicant.email) {
          body.email = email.value;
        }
        if (
          birthDate.value &&
          birthDate.value !==
            new Date(props.applicant.birthDate).toISOString().substr(0, 10)
        ) {
          body.birthDate = birthDate.value;
        }
        if (
          startDate.value &&
          startDate.value !==
            new Date(props.applicant.startDate || new Date())
              .toISOString()
              .substr(0, 10)
        ) {
          body.startDate = startDate.value;
        }
        if (
          homeVisitDate.value &&
          homeVisitDate.value !==
            new Date(props.applicant.homeVisitDate || new Date())
              .toISOString()
              .substr(0, 10)
        ) {
          body.homeVisitDate = homeVisitDate.value;
        }
        if (
          addressLine1.value &&
          addressLine1.value !== props.applicant.addressLine1
        ) {
          body.addressLine1 = addressLine1.value;
        }
        if (
          addressLine2.value &&
          addressLine2.value !== props.applicant.addressLine2
        ) {
          body.addressLine2 = addressLine2.value;
        }
        if (city.value && city.value !== props.applicant.city) {
          body.city = city.value;
        }
        if (
          stateOrTerritory.value &&
          stateOrTerritory.value !== props.applicant.stateOrTerritory
        ) {
          body.stateOrTerritory = stateOrTerritory.value;
        }
        if (zipCode.value && zipCode.value !== props.applicant.zipCode) {
          body.zipCode = zipCode.value;
        }

        if (status.value && status.value !== props.applicant.status) {
          body.status = status.value;
        }

        if (updatedPhoto && photo.value) {
          body.photo = photo.value;
        }

        if (providerUpdated.value) {
          body.provider = applicantProvider.value;
        }

        if (additionalProvidersUpdated.value) {
          body.additionalProviders = additionalProviders.value;
        }

        if (sponsorUpdated.value) {
          body.sponsor = applicantSponsor.value;
        }

        if (emergencyContactUpdated.value) {
          body.emergencyContact = applicantEmergencyContact.value;
        }

        if (
          updatedServices.length &&
          JSON.stringify(updatedServices) !==
            JSON.stringify(props.applicant.services)
        ) {
          body.services = updatedServices;
        }
        if (notesUpdated.value || newNoteAdded.value) {
          if (newNote.value && newNoteAdded.value)
            applicantNote.value.push(newNote.value);
          body.notes = applicantNote.value;
        }
      }

      if (deletedFiles.value) {
        body.deletedFiles = deletedFiles.value;
      }

      if (medicalFiles.value) {
        body.medicalFiles = medicalFiles.value;
      }

      if (serviceEmploymentFiles.value) {
        body.serviceEmploymentFiles = serviceEmploymentFiles.value;
      }

      if (homeVisitFile.value) {
        body.homeVisitFile = homeVisitFile.value;
      }

      if (applicationFile.value) {
        body.applicationFile = applicationFile.value;
      }

      if (contractFile.value) {
        body.contractFile = contractFile.value;
      }

      return body;
    }

    function submit() {
      if (
        valid.value &&
        notesValid.value &&
        filesValid.value &&
        providerValid.value &&
        sponsorValid.value &&
        emergencyContactValid.value
      ) {
        validate.value = false;
        if (props.update && props.applicant) {
          const body = getUpdates();
          if (Object.keys(body).length !== 0) {
            emit("update", body);
          } else {
            return;
          }
        } else {
          const body: ApplicantCreateBody = {
            firstName: firstName.value,
            lastName: lastName.value,
            birthDate: birthDate.value,
            addressLine1: addressLine1.value,
            city: city.value,
            stateOrTerritory: stateOrTerritory.value,
            zipCode: zipCode.value,
            email: email.value,
            services: transformServices(),
          };
          if (phone.value) {
            body.phone = "+1" + phone.value;
          }
          if (homePhone.value) {
            body.homePhone = "+1" + homePhone.value;
          }
          if (addressLine2.value) {
            body.addressLine2 = addressLine2.value;
          }
          if (photo.value) {
            body.photo = photo.value;
          }
          if (startDate.value) {
            body.startDate = startDate.value;
          }
          if (homeVisitDate.value) {
            body.homeVisitDate = homeVisitDate.value;
          }
          if (newNote.value) {
            body.notes = [newNote.value];
          }
          if (applicantSponsor.value) {
            body.sponsor = applicantSponsor.value;
          }
          if (applicantProvider.value) {
            body.provider = applicantProvider.value;
          }
          if (additionalProviders.value) {
            body.additionalProviders = additionalProviders.value;
          }
          if (applicantEmergencyContact.value) {
            body.emergencyContact = applicantEmergencyContact.value;
          }
          if (status.value) {
            body.status = status.value;
          }

          if (applicationFile.value) {
            body.applicationFile = applicationFile.value;
          }

          if (medicalFiles.value) {
            body.medicalFiles = medicalFiles.value;
          }

          if (homeVisitFile.value) {
            body.homeVisitFile = homeVisitFile.value;
          }

          if (serviceEmploymentFiles.value) {
            body.serviceEmploymentFiles = serviceEmploymentFiles.value;
          }

          if (contractFile.value) {
            body.contractFile = contractFile.value;
          }

          emit("create", body);
        }
      } else {
        if (applicantForm.value) {
          openPanels();
          validate.value = true;
          applicantForm.value.validate();
        }
      }
    }
    const { emailRules } = useEmailValidation();
    const {
      zipCodeRules,
      cityRules,
      stateRules,
      streetAddressRules,
    } = useAddressValidation();
    const { firstNameRules, lastNameRules } = useNameValidation();
    const { phoneMask, phoneRules } = usePhoneValidation();
    const { eighteenYearsAgo, birthDateRules } = useDateValidation();
    const { photoRules } = usePhotoValidation();
    const { fileRules, fileArrayRules } = useFileValidation();

    const phoneFormatRule = (value: string) =>
      value?.length > 0
        ? value.length === 10 ||
          "Phone number must include the area code, prefix and line number."
        : true;

    const applicantPhoneRules = ref<RuleFunction<string>[]>([
      phoneFormatRule,
      (value: string) =>
        value?.length > 0 ||
        homePhone.value?.length > 0 ||
        "At least one phone number is required",
    ]);

    const applicantHomePhoneRules = ref<RuleFunction<string>[]>([
      phoneFormatRule,
      (value: string) =>
        value?.length > 0 ||
        phone.value?.length > 0 ||
        "At least one phone number is required",
    ]);

    const responderRules = ref<RuleFunction<string>[]>([
      (value) =>
        ((!!militarySelected.value.length || !!value) &&
          (militarySelected.value.length >= 1 || value.length >= 1)) ||
        "Miltary Branch or Responder Type is Required.",
    ]);

    const militaryRules = ref<RuleFunction<string>[]>([
      (value) =>
        ((!!value.length || !!responderSelected.value) &&
          (value.length >= 1 || responderSelected.value.length >= 1)) ||
        "Miltary Branch or Responder Type is Required.",
    ]);

    watch(phone, (newVal) => {
      if (newVal && phone.value.length) {
        applicantHomePhoneRules.value = [phoneFormatRule];
      }
    });

    watch(homePhone, (newVal) => {
      if (newVal && homePhone.value.length) {
        applicantPhoneRules.value = [phoneFormatRule];
      }
    });

    watch(militarySelected, (newVal) => {
      if (newVal && militarySelected.value.length) {
        responderRules.value = [];
      }
    });

    watch(responderSelected, (newVal) => {
      if (newVal && responderSelected.value.length) {
        militaryRules.value = [];
      }
    });

    function changePhoto(event: File) {
      photoSource.value = URL.createObjectURL(event);
      updatedPhoto.value = true;
    }

    function changeDate(event: Date, type: string) {
      switch (type) {
        case "birthDate":
          birthDate.value = new Date(event).toISOString().substr(0, 10);
          break;
        case "startDate":
          startDate.value = new Date(event).toISOString().substr(0, 10);
          break;
        case "homeVisitDate":
          homeVisitDate.value = new Date(event).toISOString().substr(0, 10);
          break;
        default:
          null;
      }
    }

    function deleteChip(index: number, type: string) {
      if (type === "service" && serviceEmploymentFiles.value)
        serviceEmploymentFiles.value.splice(index, 1);
      if (type === "medical" && medicalFiles.value) {
        medicalFiles.value.splice(index, 1);
      }
    }

    return {
      firstName,
      lastName,
      email,
      birthDate,
      phone,
      homePhone,
      addressLine1,
      addressLine2,
      city,
      stateOrTerritory,
      zipCode,
      startDate,
      homeVisitDate,
      photo,
      submit,
      valid,
      applicantForm,
      emailRules,
      phoneRules,
      applicantPhoneRules,
      applicantHomePhoneRules,
      phoneMask,
      firstNameRules,
      lastNameRules,
      birthDateRules,
      zipCodeRules,
      cityRules,
      stateRules,
      streetAddressRules,
      STATE_LIST,
      birthDateMenu,
      startDateMenu,
      homeVisitDateMenu,
      changeDate,
      panel,
      expanded,
      openPanels,
      closeOrOpenPanels,
      photoRules,
      eighteenYearsAgo,
      picker,
      photoSource,
      changePhoto,
      militaryArray,
      responderArray,
      responderSelected,
      militarySelected,
      militaryRules,
      responderRules,
      setApplicantNote,
      applicantNote,
      notes,
      APPLICANT_STATUS_LIST,
      status,
      setApplicantSponsor,
      applicantSponsor,
      setApplicantProvider,
      applicantProvider,
      applicantEmergencyContact,
      setApplicantEmergencyContact,
      deleteNote,
      removeProvider,
      additionalProvidersIds,
      additionalProviders,
      addProvider,
      setAdditionalProvider,
      removeAdditionalProvider,
      applicationFile,
      serviceEmploymentFiles,
      deleteChip,
      homeVisitFile,
      medicalFiles,
      validate,
      deleteFile,
      setMedicalFiles,
      setServiceEmploymentFiles,
      setApplicationFile,
      setHomeVisitFile,
      fileRules,
      fileArrayRules,
      changeFiles,
      contractFile,
      setContractFile,
      takenSpecialities,
    };
  },
});
