import { RuleFunction } from "@/store";
import { ref } from "@vue/composition-api";

export function usePhoneValidation() {
  const phoneMask = ref<string>("##########");

  const phoneRules = ref<RuleFunction<string>[]>([
    (value: string) =>
      (!!value && value.length >= 1) || "Phone number is required",

    (value: string) =>
      (!!value.length && value.length === 10) ||
      "Phone number must include the area code, prefix and line number.",
  ]);

  return {
    phoneMask,
    phoneRules,
  };
}
