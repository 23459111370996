





















































































































































































































































import { defineComponent, ref, watch, watchEffect } from "@vue/composition-api";
import { ApplicantFile, Applicant } from "@/store";
import moment from "moment";
import FileDialog from "@/components/dialogs/File.vue";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import { useFileValidation } from "@/composables/validation";

interface Props {
  applicant: Applicant;
  loading: boolean;
  validate: boolean;
}

export default defineComponent({
  name: "ApplicantAttachmentUpdateForm",
  props: {
    applicant: Object,
    loading: Boolean,
    validate: Boolean,
  },
  components: {
    ConfirmationDialog,
    FileDialog,
  },
  setup(props: Props, { emit }) {
    const valid = ref<boolean>(false);
    const applicantAttachmentUpdateForm = ref<{ validate: Function } | null>(
      null
    );
    const existingServiceEmploymentFiles = ref<ApplicantFile[] | undefined>(
      ((props.applicant || {}).attachments || []).filter(
        (attachment) => attachment.type === "serviceEmploymentFile"
      )
    );
    const existingMedicalFiles = ref<ApplicantFile[] | undefined>(
      ((props.applicant || {}).attachments || []).filter(
        (attachment) => attachment.type === "medicalFile"
      )
    );
    const medicalFiles = ref<File[] | null>(null);
    const serviceEmploymentFiles = ref<File[] | null>(null);
    const existingHomeVisitFile = ref<ApplicantFile[] | File | undefined>(
      ((props.applicant || {}).attachments || []).filter(
        (attachment) => attachment.type === "homeVisitFile"
      )
    );
    const homeVisitFile = ref<File | null>(null);
    const existingApplicationFile = ref<ApplicantFile[] | File | undefined>(
      ((props.applicant || {}).attachments || []).filter(
        (attachment) => attachment.type === "applicationFile"
      )
    );
    const applicationFile = ref<File | null>(null);
    const existingContractFile = ref<ApplicantFile[] | File | undefined>(
      ((props.applicant || {}).attachments || []).filter(
        (attachment) => attachment.type === "contractFile"
      )
    );
    const contractFile = ref<File | null>(null);

    const { fileRules, fileArrayRules } = useFileValidation();

    const showApplicationInput = ref<boolean>(false);
    const showHomeVisitInput = ref<boolean>(false);
    const showMedicalInput = ref<boolean>(false);
    const showServiceEmploymentInput = ref<boolean>(false);
    const showContractInput = ref<boolean>(false);
    const deletedFiles = ref<number[]>([]);

    function getDate(date: string) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    watchEffect(() => {
      if (props.validate && applicantAttachmentUpdateForm.value) {
        applicantAttachmentUpdateForm.value.validate();
      }
    });

    watch(valid, () => {
      emit("changeFiles", valid.value);
    });

    watch(medicalFiles, (newVal) => {
      if (newVal) {
        if (
          medicalFiles.value === undefined ||
          !(medicalFiles.value || []).length
        ) {
          emit("setMedicalFiles", null);
        } else {
          emit("setMedicalFiles", medicalFiles.value);
        }
      }
    });

    watch(serviceEmploymentFiles, (newVal) => {
      if (newVal) {
        if (
          serviceEmploymentFiles.value === undefined ||
          !(serviceEmploymentFiles.value || []).length
        ) {
          emit("setServiceEmploymentFiles", null);
        } else {
          emit("setServiceEmploymentFiles", serviceEmploymentFiles.value);
        }
      }
    });

    watch(applicationFile, (newVal) => {
      if (newVal || applicationFile.value === undefined) {
        if (applicationFile.value === undefined) {
          emit("setApplicationFile", null);
        } else {
          emit("setApplicationFile", applicationFile.value);
        }
      }
    });

    watch(homeVisitFile, (newVal) => {
      if (newVal || homeVisitFile.value === undefined) {
        if (homeVisitFile.value === undefined) {
          emit("setHomeVisitFile", null);
        } else {
          emit("setHomeVisitFile", homeVisitFile.value);
        }
      }
    });

    watch(contractFile, (newVal) => {
      if (newVal || contractFile.value === undefined) {
        if (contractFile.value === undefined) {
          emit("setContractFile", null);
        } else {
          emit("setContractFile", contractFile.value);
        }
      }
    });

    function deleteApplication(file: ApplicantFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingApplicationFile.value = [];
    }

    function deleteHomeVisit(file: ApplicantFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingHomeVisitFile.value = [];
    }

    function deleteServiceEmployment(file: ApplicantFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingServiceEmploymentFiles.value = (
        (existingServiceEmploymentFiles.value as ApplicantFile[]) || []
      ).filter((attachment: ApplicantFile) => attachment.id !== file.id);
    }

    function deleteMedical(file: ApplicantFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingMedicalFiles.value = (
        (existingMedicalFiles.value as ApplicantFile[]) || []
      ).filter((attachment: ApplicantFile) => attachment.id !== file.id);
    }

    function deleteContract(file: ApplicantFile) {
      deletedFiles.value.push(file.id);
      emit("deleteFile", deletedFiles.value);
      existingContractFile.value = [];
    }

    function deleteChip(index: number, type: string) {
      if (type === "service" && serviceEmploymentFiles.value)
        serviceEmploymentFiles.value.splice(index, 1);
      if (type === "medical" && medicalFiles.value) {
        medicalFiles.value.splice(index, 1);
      }
    }

    return {
      valid,
      applicantAttachmentUpdateForm,
      serviceEmploymentFiles,
      homeVisitFile,
      existingHomeVisitFile,
      applicationFile,
      existingApplicationFile,
      medicalFiles,
      existingMedicalFiles,
      existingServiceEmploymentFiles,
      contractFile,
      existingContractFile,
      getDate,
      showApplicationInput,
      showHomeVisitInput,
      showMedicalInput,
      showServiceEmploymentInput,
      showContractInput,
      deleteApplication,
      deleteHomeVisit,
      deleteServiceEmployment,
      deleteMedical,
      deleteContract,
      deleteChip,
      fileRules,
      fileArrayRules,
    };
  },
});
