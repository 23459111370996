import { RuleFunction } from "@/store";
import { ref } from "@vue/composition-api";

/**
 * @param required Includes "Email is required" in validation rules. Defaults to true.
 */
export function useEmailValidation(required = true) {
  const verifyEmailFormat = new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,24}))$'
  );

  const emailRules = ref<RuleFunction<string>[]>([
    (value: string) => (!!value && value.length >= 1) || "Email is required",
    (value: string) =>
      (required
        ? verifyEmailFormat.test(value)
        : value === "" || verifyEmailFormat.test(value)) ||
      "Valid email is required",
    (value: string) =>
      value.length <= 100 || "Email must be less than 100 characters long",
  ]);

  return {
    emailRules: required
      ? emailRules
      : ref<RuleFunction<string>[]>(emailRules.value.slice(1)),
  };
}
