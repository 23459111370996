import { RuleFunction } from "@/store";
import { ref } from "@vue/composition-api";

export function useNameValidation() {
  const firstNameRules = ref<RuleFunction<string>[]>([
    (value: string) =>
      (!!value && value.length >= 1) || "First name is required",
    (value: string) =>
      value.length <= 100 || "First name must be less than 100 characters long",
    (value: string) =>
      /^([\w\s-,.']+)$/g.test(value) ||
      "Only alphanumeric characters and the following special characters are allowed: - , . '",
  ]);

  const lastNameRules = ref<RuleFunction<string>[]>([
    (value: string) =>
      (!!value && value.length >= 1) || "Last name is required",
    (value: string) =>
      value.length <= 100 || "Last name must be less than 100 characters long",
    (value: string) =>
      /^([\w\s-,.']+)$/g.test(value) ||
      "Only alphanumeric characters and the following special characters are allowed: - , . '",
  ]);

  const nameRules = ref<RuleFunction<string>[]>([
    (value: string) => (!!value && value.length >= 1) || "Name is required",
    (value: string) =>
      value.length <= 100 || "Name must be less than 100 characters long",
    (value: string) =>
      /^([\w\s-,.']+)$/g.test(value) ||
      "Only alphanumeric characters and the following special characters are allowed: - , . '",
  ]);

  return {
    firstNameRules,
    lastNameRules,
    nameRules,
  };
}
