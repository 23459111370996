
















































































import { defineComponent, ref, watchEffect } from "@vue/composition-api";
import { RuleFunction, ApplicantEmergencyContact } from "@/store";
import {
  useNameValidation,
  usePhoneValidation,
  useEmailValidation,
} from "@/composables/validation";
import { CONTACT_RELATIONSHIP } from "@/store/constants";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";

interface Props {
  loading: boolean;
  update?: boolean;
  contact: ApplicantEmergencyContact;
  validate?: boolean;
}

export default defineComponent({
  name: "ApplicantEmergencyContactForm",
  props: {
    loading: Boolean,
    update: Boolean,
    contact: Object,
    validate: Boolean,
  },
  components: {
    ConfirmationDialog,
  },
  setup(props: Props, { emit }) {
    const valid = ref<boolean>(false);
    const readOnly = ref<boolean>(false);
    const emergencyContactForm = ref<{ validate: Function } | null>(null);

    const { firstNameRules, lastNameRules } = useNameValidation();
    const { phoneMask, phoneRules } = usePhoneValidation();
    const relationshipRules = ref<RuleFunction<string>[]>([]);
    const emailRules = ref<RuleFunction<string>[]>([]);
    const firstName = ref<string>("");
    const lastName = ref<string>("");
    const phone = ref<string>("");
    const email = ref<string>("");
    const relationship = ref<number | null>(null);

    watchEffect(() => {
      if (props.validate && emergencyContactForm.value) {
        emergencyContactForm.value.validate();
      }
    });

    const setRequired = () => {
      const nameRules = useNameValidation();
      firstNameRules.value = nameRules.firstNameRules.value;
      lastNameRules.value = nameRules.lastNameRules.value;
      phoneRules.value = usePhoneValidation().phoneRules.value;
      relationshipRules.value = [
        (value: string) => !!value || "Relationship is required",
      ];
      emailRules.value = email.value
        ? useEmailValidation(false).emailRules.value
        : [];
    };

    const setNotRequired = () => {
      firstNameRules.value = [];
      lastNameRules.value = [];
      phoneRules.value = [];
      relationshipRules.value = [];
      emailRules.value = [];
    };

    const getDeleted = () => {
      if (props.update) {
        return (
          !relationship.value ||
          (!(firstName.value || "").trim().length &&
            !(lastName.value || "").trim().length &&
            !(phone.value || "").trim().length &&
            !(email.value || "").trim().length)
        );
      } else return;
    };

    const setFields = async (contact: ApplicantEmergencyContact) => {
      firstName.value = contact.firstName;
      lastName.value = contact.lastName;
      phone.value = contact.phone.slice(2, 13);
      relationship.value = contact.relationship;
      if (contact.email) {
        email.value = contact.email;
      }
      await setRequired();
    };

    const clearAll = async () => {
      await setNotRequired();
      firstName.value = "";
      lastName.value = "";
      phone.value = "";
      email.value = "";
      relationship.value = null;
    };

    const deleteContact = async () => {
      await clearAll();
      if (props.update && props.contact) {
        emit("applicantEmergencyContact", {
          valid: valid.value,
          deleted: getDeleted(),
        });
      }
    };

    const setContact = async () => {
      if (
        relationship.value ||
        (firstName.value || "").trim().length ||
        (lastName.value || "").trim().length ||
        (phone.value || "").trim().length ||
        (email.value || "").trim().length
      ) {
        await setRequired();
      } else {
        await setNotRequired();
      }

      emit("applicantEmergencyContact", valid.value);
      watchEffect(() => {
        if (valid.value && emergencyContactForm.value) {
          setTimeout(() => {
            emit("applicantEmergencyContact", {
              contact: {
                firstName: firstName.value,
                lastName: lastName.value,
                phone: phone.value,
                email: email.value,
                relationship: relationship.value,
              },
              valid: valid.value,
              deleted: getDeleted(),
            });
          }, 300);
        }
      });
    };

    const showDelete = () => {
      return !!(props.update && (props.contact || {}).id);
    };

    if (props.update) {
      if (props.contact) {
        setFields(props.contact as ApplicantEmergencyContact);
      } else {
        setNotRequired();
      }
    }

    return {
      valid,
      emergencyContactForm,
      firstName,
      lastName,
      phone,
      email,
      relationship,
      firstNameRules,
      lastNameRules,
      phoneMask,
      phoneRules,
      emailRules,
      relationshipRules,
      setContact,
      deleteContact,
      showDelete,
      CONTACT_RELATIONSHIP,
      readOnly,
    };
  },
});
