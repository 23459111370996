

















































































import { defineComponent, ref, watchEffect } from "@vue/composition-api";
import { RuleFunction } from "@/store";
import {
  usePhoneValidation,
  useEmailValidation,
  useNameValidation,
} from "@/composables/validation";
import { SPONSOR_RELATIONSHIP } from "@/store/constants";
import { Sponsor } from "@/store/interfaces";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";

interface Props {
  loading: boolean;
  update?: boolean;
  sponsor?: Sponsor;
  validate?: boolean;
}
export default defineComponent({
  name: "SponsorForm",
  props: {
    loading: Boolean,
    update: Boolean,
    sponsor: Object,
    validate: Boolean,
  },
  components: {
    ConfirmationDialog,
  },
  setup(props: Props, { emit }) {
    const { phoneMask } = usePhoneValidation();
    const firstName = ref<string>("");
    const lastName = ref<string>("");
    const relationship = ref<number | null>(null);
    const phone = ref<string>("");
    const email = ref<string>("");
    const firstNameRules = ref<RuleFunction<string>[]>([]);
    const lastNameRules = ref<RuleFunction<string>[]>([]);
    const phoneRules = ref<RuleFunction<string>[]>([]);
    const relationshipRules = ref<RuleFunction<string>[]>([]);
    const { emailRules } = useEmailValidation();
    const valid = ref<boolean>(false);
    const sponsorForm = ref<{ validate: Function } | null>(null);
    const readOnly = ref<boolean>(false);

    watchEffect(() => {
      if (props.validate && sponsorForm.value) {
        sponsorForm.value.validate();
      }
    });

    function setRequired() {
      relationshipRules.value = [
        (value: string) => !!value || "Relationship is required",
      ];
      const allNameRules = useNameValidation();
      firstNameRules.value = allNameRules.firstNameRules.value;
      lastNameRules.value = allNameRules.lastNameRules.value;
      phoneRules.value = usePhoneValidation().phoneRules.value;
      emailRules.value = email.value
        ? useEmailValidation(false).emailRules.value
        : [];
    }
    function setNotRequired() {
      firstNameRules.value = [];
      lastNameRules.value = [];
      phoneRules.value = [];
      relationshipRules.value = [];
      emailRules.value = [];
    }

    function getDeleted() {
      if (props.update) {
        return (
          !relationship.value ||
          (!(firstName.value || "").trim().length &&
            !(lastName.value || "").trim().length &&
            !(phone.value || "").trim().length &&
            !(email.value || "").trim().length)
        );
      } else return;
    }

    async function setSponsor() {
      if (
        relationship.value ||
        (firstName.value || "").trim().length ||
        (lastName.value || "").trim().length ||
        (phone.value || "").trim().length ||
        (email.value || "").trim().length
      ) {
        await setRequired();
      } else {
        await setNotRequired();
      }

      emit("applicantSponsor", valid.value);

      watchEffect(() => {
        if (valid.value && sponsorForm.value) {
          setTimeout(() => {
            emit("applicantSponsor", {
              sponsor: {
                firstName: firstName.value,
                lastName: lastName.value,
                phone: phone.value,
                email: email.value,
                relationship: relationship.value,
              },
              valid: valid.value,
              deleted: getDeleted(),
            });
          }, 300);
        }
      });
    }

    async function setFields(sponsor: Sponsor) {
      firstName.value = sponsor.firstName;
      lastName.value = sponsor.lastName;
      phone.value = sponsor.phone.slice(2, 13);
      relationship.value = sponsor.relationship;
      if (sponsor.email) {
        email.value = sponsor.email;
      }
      await setRequired();
    }

    async function clearAll() {
      await setNotRequired();
      firstName.value = "";
      lastName.value = "";
      phone.value = "";
      email.value = "";
      relationship.value = null;
    }

    async function deleteSponsor() {
      await clearAll();
      if (props.update && props.sponsor) {
        emit("applicantSponsor", {
          valid: valid.value,
          deleted: getDeleted(),
        });
      }
    }

    function showDelete() {
      return props.update && (props.sponsor || {}).id;
    }

    if (props.update) {
      if (props.sponsor) {
        setFields(props.sponsor as Sponsor);
      } else {
        setNotRequired();
      }
    }

    return {
      valid,
      sponsorForm,
      phoneMask,
      firstName,
      lastName,
      relationship,
      relationshipRules,
      firstNameRules,
      lastNameRules,
      phoneRules,
      emailRules,
      email,
      phone,
      setSponsor,
      SPONSOR_RELATIONSHIP,
      readOnly,
      showDelete,
      deleteSponsor,
    };
  },
});
